import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  //mode: 'hash',
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'login' } },
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// NO SE UTILIZARA NAVEGACIÓN DE VUE

// router.beforeEach((to, _, next) => {
//   // const isLoggedIn = isUserLoggedIn()
//   // const userData = getUserData()

//   // if (!canNavigate(to)) {
//   //   // Redirect to login if not logged in
//   //   if (!isLoggedIn) return next({ name: 'auth-login-v1' })

//   //   // If logged in => not authorized
//   //   return next({ name: 'misc-not-authorized' })
//   // }

//   // // Redirect if logged in
//   // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//   //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   // }

//   // // check if route is restricted by role
//   // if (isLoggedIn && !!to.meta.authorize) {
//   //   // role not authorised so redirect to home page
//   //   return next({ path: '/' })
//   // }

//   // return next()
// })

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
