import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    loginAttempts: 0,
    loginLocked: false,
  },
  getters: {

    isLoginLocked: state => {
      const { loginLocked } = state

      if(loginLocked){
        return true
      }

      else{
        return false
      }

    },

    attempts: state=> {
      const { loginAttempts } = state
      return loginAttempts
    },

    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    LOGIN_ATTEMPT(state) {
      state.loginAttempts += 1

      if(state.loginAttempts == 3){
        var actualMinutes = Date.now()+120000
        localStorage.setItem('today', actualMinutes)
        state.loginAttempts = 0
      }

    },
  },
  actions: {},
}
